import { Logger, UserSettings } from 'timeghost-api';
const log = new Logger('Freshdesk JS');
interface FreshChatEvent {
  success: boolean;
  status: number;
  data: any;
}
const waitForFreshdeskInstance = () => {
  return new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      if (typeof window.FreshworksWidget === 'function') resolve(window.FreshworksWidget);
      else waitForFreshdeskInstance().then(resolve);
    }, 1000);
  });
};
const WIDGET_ID = `77000003752`;
const SCRIPT_URL = `https://euc-widget.freshworks.com/widgets/${WIDGET_ID}.js`;
export const isFreshChatInitialized = () => typeof window.FreshworksWidget === 'function';
export const initFreshChatJs = (userId?: string) => {
  if (typeof window.FreshworksWidget === 'function') {
    return Promise.resolve(window.FreshworksWidget);
  }
  window.fwSettings = {
    widget_id: WIDGET_ID,
  };
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = SCRIPT_URL;
    script.onload = resolve;
    script.dataset.js = 'freshdesk';
    script.async = true;
    script.onerror = reject;
    document.head.appendChild(script);
  })
    .then(waitForFreshdeskInstance)
    .then((freshChat) => {
      if (!freshChat) throw new Error('Freshdesk widget instance not loaded or not found');
      freshChat('hide', 'launcher');
      freshChat('identify', 'ticketForm', {
        userId,
      });
      return freshChat;
    })
    .catch((err) => {
      log.error(err);
      throw err;
    });
};
