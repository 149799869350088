import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterContentInit, Component, Inject, OnDestroy, OnInit, signal } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { fetchMessageChannel } from '@app/_helpers/utils';
import { FadeInOut, SlideUpDown } from '@app/animations/fade';
import { AppService } from '@app/app.service';
import { environment } from '@env/environment';
import { endOfMinute } from 'date-fns/esm';
import produce from 'immer';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { firstBy } from 'thenby';
import { Logger, MyTimesQuery, MyTimesService, UserService, UserSettingsQuery, WorkspacesQuery } from 'timeghost-api';
import { UserWorkspace, Workspace } from 'timeghost-api/lib/model/workspace';
import { CreateWorkspaceComponent } from '../create-workspace/create-workspace.component';

@Component({
  selector: 'app-workspace-dialog',
  templateUrl: './workspace-dialog.component.html',
  styleUrls: ['./workspace-dialog.component.scss'],
  animations: [SlideUpDown, FadeInOut],
})
export class WorkspaceDialogComponent implements OnInit, AfterContentInit, OnDestroy {
  get mobileWorkspaceName() {
    return this.media.isMatched(`(max-width: 768px)`);
  }
  private _isLoading = new BehaviorSubject<boolean>(true);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }

  get workspaces(): Workspace[] {
    return this.userSettingsQuery.getValue().workspaces as any;
  }

  nameInput: UntypedFormControl = new UntypedFormControl('', [Validators.minLength(3)]);
  readonly nameInput$: Observable<string> = this.nameInput.valueChanges.pipe(distinctUntilChanged());
  workspace$filtered = combineLatest([
    this.nameInput$.pipe(startWith(''), distinctUntilChanged()),
    this.workspaceQuery.selectAll(),
    this.userSettingsQuery.select((x) => x.workspace.id),
  ]).pipe(
    map(([search, workspaces, selectedWorkspaceId]) => {
      return (() => {
        if (!search?.length) return workspaces;
        return workspaces.filter((x) => x && x.name && x.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
      })().map((w) => ({ ...w, selected: w.id === selectedWorkspaceId, users: w.users.filter((y) => !y.removed) }));
    }),
    map((x) => {
      return x.sort(firstBy((w) => w['modified'], 'desc').thenBy('name', 'asc'));
    }),
  );

  constructor(
    private userSettingsQuery: UserSettingsQuery,
    private dialogRef: MatDialogRef<WorkspaceDialogComponent>,
    private dialog: MatDialog,
    private workspaceQuery: WorkspacesQuery,
    private appService: AppService,
    private myTimesService: MyTimesService,
    private myTimesQuery: MyTimesQuery,
    @Inject(MAT_DIALOG_DATA)
    private data: any,
    private userService: UserService,
    private media: BreakpointObserver,
  ) {}
  get production() {
    return environment.production;
  }
  ngAfterContentInit() {
    this.isLoading = false;
  }
  ngOnInit() {
    this.dialogRef.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    this.dialogRef.updatePosition({
      left: '16px',
      bottom: '16px',
    });
    this.dialogRef.updateSize('640px', '580px');
  }
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  get currentWorkspace() {
    return this.userSettingsQuery.getValue().workspace;
  }
  isCurrentWorkspace(ws: UserWorkspace) {
    return ws.id === this.currentWorkspace.id;
  }
  trackId(index: number, { id }: { id: string }) {
    return id;
  }
  loading = signal(false);
  changeWorkspace(ws: UserWorkspace) {
    this.dialogRef.disableClose = true;
    const workspaceId = ws.id;
    this.loading.set(true);
    return this.myTimesService
      .getLatestRecordings(1)
      .then((times) => times?.[0])
      .then((time) => {
        if (time) {
          const newTime = produce(time, (draft) => {
            draft.end = endOfMinute(new Date()).toISOString();
          });
          return this.myTimesService
            .update(newTime)
            .toPromise()
            .then((x) => {
              this.isLoading = true;
              if (this.data?.showLoading) this.appService.addLoading('fullAppLoading');
              return this.userService.changeWorkspace(ws);
            })
            .then((x) => {
              this.appService.resetStores();
              fetchMessageChannel()?.postMessage?.({ workspaceId: x.workspace.id, _source: 'tg' });
              return this.appService.reinitializeStores();
            })
            .then(() => {
              return this.dialogRef.close(this.workspaceQuery.getEntity(ws.id));
            });
        } else {
          if (this.data?.showLoading) this.appService.addLoading('fullAppLoading');
          return this.userService
            .changeWorkspace(ws)
            .then((x) => {
              this.appService.resetStores();
              fetchMessageChannel()?.postMessage?.({ workspaceId: x.workspace.id, _source: 'tg' });
              return this.appService.reinitializeStores();
            })
            .then(() => {
              return this.dialogRef.close(this.workspaceQuery.getEntity(ws.id));
            });
        }
      })
      .then(() => {
        if (workspaceId) localStorage.activeWorkspace = workspaceId;
      })
      .finally(() => {
        this.loading.set(false);
      });
  }

  abort() {
    this.dialogRef.close();
  }
  openCreateDialog() {
    return this.dialog
      .open(CreateWorkspaceComponent, {
        data: {
          name: this.nameInput.value || '',
        },
      })
      .afterClosed()
      .pipe(
        filter((x) => !!x),
        switchMap((x) => this.changeWorkspace(x)),
      )
      .subscribe();
  }
}
const log = new Logger('WorkspaceDialogComponent');
