<app-loader
  [isActive]="this.isLoading$ | async"
  [enableCircle]="true"
  [isAfterViewInit]="false"
  [width]="20"
></app-loader>
<div matDialogTitle class="mb-2 mt-3 items-center justify-start">
  <span>{{ 'workspace.plural' | translate }}</span>
  <div class="flex-auto"></div>
  <button *ngIf="!this.isLoading" mat-icon-button matDialogClose tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-dialog-flex-content flex flex-col gap-2">
  <mat-form-field appearance="fill" class="mat-form-field-no-hint mt-2 pb-0">
    <mat-label>{{ 'utils.search' | translate }}</mat-label>
    <input type="text" matInput [formControl]="this.nameInput" />
  </mat-form-field>
  <ng-container *ngIf="this.workspace$filtered | async as ws">
    <ng-container *ngIf="ws.length > 0; else noitemno">
      <div class="-mx-24px scroll-auto px-24px pb-24px pt-1">
        <div gdArea gdColumns="1fr" gdColumns.gt-xs="1fr 1fr" gdColumns.gt-md="1fr 1fr 1fr" gdGap="16px">
          <ng-container *ngFor="let item of ws; trackBy: trackId">
            <div
              class="workspace-card pos-relative cursor-pointer"
              matRipple
              (click)="!item.selected && this.changeWorkspace(item)"
              [class.workspace-card-active]="item.selected"
              matTooltip
              [matTooltipTemplate]="workspaceTooltip"
              [matTooltipTemplateContext]="{ $implicit: item }"
              [matTooltipDisabled]="mobileWorkspaceName ? item.name.length < 50 : item.name.length < 20 || loading()"
              [ngClass]="{ 'opacity-50': loading() }"
              [disabled]="loading()"
            >
              <div class="flex flex-row items-center justify-start gap-4">
                <div class="flex flex-col">
                  <strong>{{ item.name | truncate: 24 }}</strong>
                  <div class="mat-hint line-clamp-2">{{ item.description | truncate: 50 }}</div>
                </div>
              </div>
              <div class="flex-auto"></div>
              <div class="workspace-card-users">
                <div class="workspace-card-user workspace-card-user-shift" *ngIf="item.users.length > 5">
                  <div class="workspace-card-user-image workspace-card-user-image-default">
                    +{{ item.users.length - 5 | clamp: 0 : 9 }}
                  </div>
                </div>
                <div
                  class="workspace-card-user"
                  [class.workspace-card-user-shift]="!last"
                  *ngFor="let user of item.users | slice: 0 : 5; let first = first; let last = last; trackBy: trackId"
                >
                  <app-user-avatar
                    size="24px"
                    class="workspace-card-user-image"
                    [userId]="'default'"
                    matTooltip
                    [matTooltipTemplate]="userHoverCard"
                    [matTooltipTemplateContext]="{ user: user }"
                    defaultImageSource="/assets/img/default.png"
                  ></app-user-avatar>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-template #noitemno>
      <p class="mat-hint text-center">{{ 'errors.empty' | translate }}</p>
    </ng-template>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="mat-action-filled wflex-[0_0_auto]">
  <button mat-flat-button class="mat-action-filled" color="primary" (click)="this.openCreateDialog()">
    {{ 'workspace.create' | translate }}
  </button>
</mat-dialog-actions>
<ng-template #userHoverCard let-user="user">
  <div class="-mx-1 flex flex-row items-center justify-start gap-2">
    <app-user-avatar size="40px" class="rounded-xs" [userId]="user.id"></app-user-avatar>
    <div class="mat-typography flex flex-col">
      <div>{{ user.name }}</div>
      <div class="mat-hint">{{ user.email | truncate: 42 }}</div>
    </div>
  </div>
</ng-template>
<ng-template #workspaceTooltip let-ws>
  <div class="mat-typography flex flex-col">
    <div class="font-bold">{{ ws.name }}</div>
    <div class="mat-hint">{{ ws.description }}</div>
  </div>
</ng-template>
