import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { WorkspacesService, UserService, Logger, UserSettingsQuery, WorkspacesQuery } from 'timeghost-api';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { OfficeUser } from '@app/_classes/user-office';
import {
  map,
  filter,
  tap,
  debounceTime,
  startWith,
  finalize,
  switchMap,
  takeWhile,
  expand,
  reduce,
  distinctUntilChanged,
} from 'rxjs/operators';
import { BehaviorSubject, defer, of, from } from 'rxjs';

import { KeyValStoreService } from '@app/_services/key-val-store.service';
import { Router } from '@angular/router';
import { CustomValidators } from '@app/_validators/custom-validators';
import errorUtils from '@app/_classes/error-utils';
import { AppService } from '@app/app.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-workspace',
  templateUrl: './create-workspace.component.html',
  styleUrls: ['./create-workspace.component.scss'],
})
export class CreateWorkspaceComponent implements OnInit, AfterViewInit {
  wsGroup = new UntypedFormGroup(
    {
      name: new UntypedFormControl('', [Validators.required, CustomValidators.minLength(3)]),
      description: new UntypedFormControl(''),
      users: new UntypedFormControl('', [
        () => () => {
          if (!!this.selectedUsers) {
            return {
              ValidSelectedUsers: true,
            };
          }
          return null;
        },
      ]),
    },
    {
      validators: [
        ({ root: ctrl }) => {
          const ctrls = (ctrl as UntypedFormGroup).controls;
          if (this.workspaceQuery.getCount((x) => x.name?.toLowerCase() === `${ctrls.name.value}`.toLowerCase()) > 0) {
            ctrls.name.setErrors({ ...ctrls.name.errors, exists: true });
            return { exists: true };
          }
          return null;
        },
      ],
      updateOn: 'change',
    },
  );
  private _filteredUsers: BehaviorSubject<OfficeUser[]> = new BehaviorSubject([]);
  filteredUsers = this._filteredUsers.asObservable();
  selectedUsers: OfficeUser[] = [];
  get filteredUsersNotSelected() {
    return this.filteredUsers.pipe(
      map((x) => x.filter((y) => this.selectedUsers.filter((v) => v.id === y.id).length === 0)),
    );
  }
  private _isLoadingAsync: BehaviorSubject<boolean> = new BehaviorSubject(false);
  get isLoadingAsync() {
    return this._isLoadingAsync.asObservable();
  }
  get isLoading() {
    return this._isLoadingAsync.value;
  }
  set isLoading(val: boolean) {
    this._isLoadingAsync.next(val);
  }
  get officeUsers() {
    return this.keyvalStore.get<OfficeUser[]>('_officeUsers', null);
  }
  set officeUsers(val: OfficeUser[]) {
    this.keyvalStore.set('_officeUsers', val);
  }
  constructor(
    private appService: AppService,
    private ws: WorkspacesService,
    private userSettingsQuery: UserSettingsQuery,
    private workspaceQuery: WorkspacesQuery,
    private userService: UserService,
    private ref: MatDialogRef<CreateWorkspaceComponent>,
    private keyvalStore: KeyValStoreService,
    @Inject(MAT_DIALOG_DATA)
    private data: { name: string },
    private router: Router,
  ) {
    if (data?.name) {
      this.wsGroup.controls.name.setValue(data.name);
    }
  }

  ngOnInit() {
    this.ref.updateSize('500px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    this.wsGroup.controls.users.valueChanges
      .pipe(
        startWith(''),
        distinctUntilChanged(),
        debounceTime(500),
        filter((x) => x != null && typeof x === 'string' && x.length >= 3),
      )
      .subscribe((x: string) => {
        this.filterByName(x);
      });
  }

  get formNameErrors() {
    if (this.wsGroup.controls.name.errors?.required) return { content: 'errors.required', args: { field: 'Name' } };
    if (this.wsGroup.controls.name.errors?.minlength)
      return { content: 'errors.minlength', args: { field: 'Name', length: 3 } };
    if (this.wsGroup.controls.name.errors?.exists) return { content: 'errors.unique', args: { field: 'Name' } };
    return null;
  }
  ngAfterViewInit(): void {}
  filterByName(name: string) {
    defer(() => {
      this.isLoading = true;
      let i = 0;
      let skip = 10;
      return of(<OfficeUser[]>[
        {
          id: '__DELETE',
        },
      ]).pipe(
        startWith(''),
        debounceTime(1000),
        expand(() => {
          return from(this.userService.getPeople(name, i)).pipe(tap(() => (i += skip)));
        }),
        takeWhile((x: OfficeUser[]) => {
          log.debug(['tkw', x]);
          return x !== undefined && x.length > 0;
        }),
        reduce((next: OfficeUser[], data: OfficeUser[]) => {
          return next.concat(data || []);
        }),
        map((x) => x.filter((y) => y.id !== '__DELETE' && y.personType.class.toLowerCase() === 'person')),
        finalize(() => (this.isLoading = false)),
      );
    }).subscribe((x) => {
      this.officeUsers = x;
      this._filteredUsers.next(x || []);
    });
  }

  selectAfterwards: boolean = true;
  addEntry() {
    if (this.wsGroup.invalid) {
      return;
    }
    defer(() => {
      this.isLoading = true;
      const userSettings = this.userSettingsQuery.getValue();
      return this.ws.add({
        name: this.wsGroup.controls.name.value,
        description: this.wsGroup.controls.description.value || '',
        users: [
          {
            email: userSettings.officeProfile.mail,
            name: userSettings.officeProfile.displayName,
            admin: true,
            id: userSettings.id,
          },
        ],
      });
    }).subscribe(
      (x) => {
        this.ref.close(x);
      },
      (err: HttpErrorResponse) => {
        if (errorUtils.isUniqueError(err)) {
          this.wsGroup.controls.name.setErrors({
            exists: true,
          });
        }
      },
    );
  }
  displayUserBy(val: OfficeUser): string | undefined {
    return val ? val.displayName : undefined;
  }
  userSelected(ev: MatAutocompleteSelectedEvent) {
    ev.option.deselect();
    this.addUser(ev.option.value);
  }
  addUser(user: OfficeUser) {
    this.selectedUsers.push(user);
    this.wsGroup.controls.users.setValue(null);
  }
  removeUser(user: OfficeUser) {
    let ind = this.selectedUsers.indexOf(user);
    if (ind > -1) this.selectedUsers.splice(ind, 1);
  }
}
const log = new Logger(CreateWorkspaceComponent.name);
