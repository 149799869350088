<app-loader [isActive]="this.isLoadingAsync | async" [enableCircle]="true"></app-loader>
<h1 matDialogTitle>
  <div class="flex flex-row items-center justify-start">
    <div>{{ 'workspace.create' | translate }}</div>
    <div class="flex-auto"></div>
    <button mat-icon-button matDialogClose tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h1>

<mat-dialog-content>
  <form (submit)="$event.preventDefault()" [formGroup]="this.wsGroup">
    <div class="flex flex-col items-stretch justify-start">
      <mat-form-field>
        <mat-label>{{ 'Name' | translate }}</mat-label>
        <input matInput type="text" formControlName="name" />
        <mat-error *ngIf="this.formNameErrors as error">{{ error.content | translate: error.args || {} }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Description' | translate }}</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="mat-action-filled">
  <button
    mat-flat-button
    color="primary"
    class="mat-action-filled"
    [disabled]="this.wsGroup.invalid"
    (click)="this.addEntry()"
  >
    {{ 'workspace.create' | translate }}
  </button>
</mat-dialog-actions>
